<template>
	<div></div>
</template>
<script setup>
import jwt from '@/api/LocalStorage';
import { useRouter } from 'vue-router';
import ajax from '@/api/api-manager';
import { ref } from 'vue';
import { userInfoStore } from '@/store/UserStore';

const ID_TOKEN_KEY = ref('id_token');
const store = userInfoStore();
const router = useRouter();
// const naver_id_login = new window.naver_id_login('TEA0Alw6wlCncJct7Oda', 'http://localhost:3000/login/naver');
const naver_id_login = new window.naver_id_login('TEA0Alw6wlCncJct7Oda', 'https://www.toonflex.co.kr:3000/login/naver');

// 네이버 사용자 프로필 조회 이후 프로필 정보를 처리할 callback
setTimeout(() => {
	const email = naver_id_login.getProfileData('email');
	const snsform = new FormData();
	console.log(email);
	snsform.append('id', email);
	snsform.append('userType', 'NA');
	store.naver_token = naver_id_login.oauthParams.access_token;
	ajax('user/kakao_login', snsform).then((response) => {
		jwt.saveToken('id', email);
		jwt.saveToken(ID_TOKEN_KEY.value, response.data.data.token);
		jwt.saveToken('userNo', response.data.data.userNo);
		jwt.saveToken('naver', naver_id_login.oauthParams.access_token);
		jwt.saveToken('nickname', response.data.data.nickname);
		jwt.saveToken('loginType', 'naver');
		// jwt.saveToken('membership', response.data.data.membershipYn);
		window.close();
		opener.location.href = '/';
	});
}, 100);
// 네이버 사용자 프로필 조회
naver_id_login.get_naver_userprofile();
</script>
